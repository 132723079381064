// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gu-app-primary: mat.define-palette(mat.$indigo-palette);
$gu-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gu-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gu-app-theme: mat.define-light-theme((color: (primary: $gu-app-primary,
        accent: $gu-app-accent,
        warn: $gu-app-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gu-app-theme);


// Typography...
// Specify "ROboto" as the default font family for all levels.
$kids-typography: mat.define-typography-config($font-family: 'Roboto'
  );

// Now we have sweet buttons with ROboto.
@include mat.button-typography($kids-typography);
@include mat.typography-hierarchy($kids-typography);

//-----------------
/* You can add global styles to this file, and also import other style files */
// FrontRamon

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here

html,
body {
  height: 100%;
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, "Source Sans Pro", sans-serif;
}

* {
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, "Source Sans Pro", sans-serif;
}

body {
  margin: 0;
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, "Source Sans Pro", sans-serif;
  ;
}

.primaryColor {
  color: #3f51b5;

}

.translateX {
  transform: translate(-50%, -50%);
}

.square {
  width: 30px;
  height: 30px;
}

.cover {
  object-fit: cover;
}

.dots {
  background: radial-gradient(circle, #4f46e4 25%, transparent 26%);
  background-size: 3em 3em;
  background-color: #ffffff;
  opacity: 1
}

.blurThis {
  backdrop-filter: blur(11px);
}

.professor_photo {
  width: 200px;
  height: 200px;
}

.icon_photo {
  border-color: #4f46e4 25%;
  border: 1px solid rgb(0, 127, 192);
  // border-style: outset;
  border-inline-width: 1px;
  width: 100%;
  height: 100%;
}

.fit-cover {
  object-fit: cover;
}

.border_photo {
  border: 3.5px;
}

.ais-CurrentRefinements {
  overflow-x: hidden;
  width: 100vw;
  max-width: 100%;
  margin-bottom: 4px;
}

/* Estilo para el contenedor del widget */
.ais-CurrentRefinements-list {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 3px;
}

/* Estilo para cada ítem de refinamiento */
.ais-CurrentRefinements-item {
  display: inline-flex;
  padding: 2px 3px;
  cursor: pointer;
  white-space: nowrap;
}

/* Estilo para la etiqueta del refinamiento */
.ais-CurrentRefinements-label {
  font-weight: bold;
  margin-right: 3px;
}

/* Estilo para stepper Material UI */

.mat-step-label, .mat-checkbox-layout{
  white-space: normal !important;
  word-wrap: normal !important;
  word-break: normal !important;
  text-align: justify !important;
  gap:1rem;
}

.mat-checkbox-inner-container {
  margin: 0 !important; 
}
.mat-vertical-stepper-header{
  height: auto !important;
  padding: 16px;
}